'use client';

import { useMarket } from '@app/_context';
import { Dialog } from '@headlessui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { motion } from 'framer-motion';
import { useRouter } from 'next/navigation';
import type { ComponentProps } from 'react';
import { useLayoutEffect } from 'react';
import type { Dictionary } from 'types';

import { getMarketConfigFromLocale } from '@/config/market-configurations';
import markets from '@/config/markets';
import type { MarketConfiguration } from '@/config/markets/const';
import { RegionEnum } from '@/config/markets/const';
import { TRANSITION_CUBIC_BEZIER } from '@/config/transitions';
import { cn } from '@/lib/utils';
import getLocaleFromPathname from '@/utils/locale/getLocaleFromPathname';

import Flag from '../Flag';
import { Heading, Paragraph } from '../ui';
import { interceptRoute } from './interceptRoute';

const nordics = markets.filter(
    (market) => market.region === RegionEnum.nordics,
);
const europe = markets.filter((market) => market.region === RegionEnum.europe);

type MarketRelayState = {
    open: boolean;
    type: 'popup' | 'manual';
    suggestion?: MarketConfiguration;
};

export function MarketItem({
    market,
    className,
}: { market: MarketConfiguration } & ComponentProps<'li'>) {
    const queryClient = useQueryClient();
    const router = useRouter();
    const { market: mrkt } = useMarket().state;
    return (
        <li className={cn('block', className)}>
            <a
                data-market={market.code}
                data-lang={market.language.code}
                onClick={(e) => interceptRoute(e, router, mrkt, queryClient)}
                href={market.link}
                className="flex items-center gap-2 whitespace-nowrap bg-gray-50 p-3 transition-all focus-within:bg-gray-100 hover:bg-gray-100">
                <Flag
                    code={market.code}
                    className="h-4 w-4 rounded-full border"
                />
                <Heading as="h3" className="flex-grow ">
                    {market.label}
                </Heading>
                <Paragraph className="uppercase text-gray-400">
                    {market.language.code} / {market.currency}
                </Paragraph>
            </a>
        </li>
    );
}

export function MarketRelay() {
    return (
        <div className="grid h-full w-full gap-y-4 max-md:grid-flow-row md:grid-cols-3">
            <section className="">
                <Heading size={5} className="top-0 bg-white p-2" as="h3">
                    Nordics
                </Heading>
                <ul className="gap-0 md:columns-1">
                    {nordics?.map((market) => (
                        <MarketItem
                            market={market}
                            key={market.code}
                            className="px-2 py-1"
                        />
                    ))}
                </ul>
            </section>
            <section className="md:col-span-2">
                <Heading size={5} className="top-0 bg-white p-2" as="h3">
                    Rest of Europe
                </Heading>
                <ul className="gap-0 md:columns-2">
                    {europe?.map((market) => (
                        <MarketItem
                            market={market}
                            key={market.code}
                            className="px-2 py-1"
                        />
                    ))}
                </ul>
            </section>
        </div>
    );
}

export default function MarketPopup({
    dictionary,
}: {
    dictionary: Dictionary;
}) {
    const queryClient = useQueryClient();
    const router = useRouter();
    const { market } = useMarket().state;
    const { data: state } = useQuery<MarketRelayState>({
        queryKey: ['market'],
    });

    useLayoutEffect(() => {
        const market = getCookie('ng_market')?.valueOf();
        const suggested = getCookie('ng_suggestion')?.valueOf();
        if (typeof suggested === 'string') {
            if (suggested === market) deleteCookie('ng_suggestion');
            const suggestion = markets.find((m) => m.code === suggested);
            queryClient.setQueryData(['market'], {
                open: true,
                suggestion,
                type: 'popup',
            });
        } else if (typeof market !== 'string') {
            queryClient.setQueryData(['market'], { open: true, type: 'popup' });
        }
    }, [queryClient]);

    const onClose = () => {
        if (state?.type === 'popup') return;
        const locale = getLocaleFromPathname(window.location.pathname);
        const market = getMarketConfigFromLocale(locale);
        setCookie('ng_market', market.code);
        setCookie('ng_lang', market.language.code);
        deleteCookie('ng_suggestion');
        queryClient.setQueryData(['market'], { open: false });
    };

    return (
        <Dialog
            className="relative z-[100] flex flex-col"
            open={Boolean(state?.open)}
            onClose={onClose}>
            <Dialog.Overlay className="fixed inset-0 bg-black/70" />
            <motion.div
                initial={{ y: 16, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 16, opacity: 0 }}
                transition={TRANSITION_CUBIC_BEZIER}
                className="fixed inset-0 h-full w-full overflow-y-auto max-lg:self-end">
                <Dialog.Panel className="relative mx-auto mb-4 mt-[10vh] grid min-h-content max-w-screen-lg grid-flow-row gap-4 bg-white px-4 py-8 md:px-8 lg:mt-4">
                    {state?.type === 'popup' && (
                        <>
                            <Heading
                                size={4}
                                as="h1"
                                className="mx-auto max-w-screen-md text-center">
                                {state?.suggestion
                                    ? dictionary.browsingFromLocation?.replace(
                                          '%s',
                                          state?.suggestion.label,
                                      )
                                    : dictionary.welcomeToNG}
                            </Heading>
                            <Paragraph className="mx-auto max-w-screen-md text-center">
                                {state?.suggestion
                                    ? dictionary.browseOurLocalizedVersion?.replace(
                                          '%s',
                                          state?.suggestion.descriptiveLabel,
                                      )
                                    : `${dictionary.pleaseChooseCountry} ${dictionary.countryNotListed}`}
                            </Paragraph>
                            {state?.suggestion && (
                                <a
                                    data-market={state?.suggestion.code}
                                    data-lang={state?.suggestion.language.code}
                                    onClick={(e) =>
                                        interceptRoute(
                                            e,
                                            router,
                                            market,
                                            queryClient,
                                        )
                                    }
                                    href={state?.suggestion.link}
                                    className="mx-auto mt-4 flex w-full place-content-center place-items-center border border-black bg-black px-6 py-3 text-center tracking-wide text-white max-lg:text-sm lg:max-w-120">
                                    Continue to {state?.suggestion.label}
                                </a>
                            )}
                        </>
                    )}
                    <MarketRelay />
                </Dialog.Panel>
            </motion.div>
        </Dialog>
    );
}
